<template>
  <div>
    <!-- Page Header -->
    <base-page-header title="Bankrate Billing Tool">
      <template v-slot:buttons>
        <div class="row mt-3 mt-md-0">
          <div class="col-md-12 d-flex justify-content-md-end">
            <button class="btn bg-green me-2" @click="modals.bankRateUpload = true">
              <span>Upload Bank rate CSV</span>
            </button>
            <button class="btn btn-primary" @click="loadLeads">
              <span v-if="ui.loadLeads" aria-hidden="true" class="spinner-border spinner-border-sm me-2" role="status"></span>
              <span>Load leads</span>
            </button>
          </div>
        </div>
      </template>
    </base-page-header>

    <!-- Page Spinner -->
    <page-spinner v-if="ui.loading" />

    <!-- Page Content -->
    <base-page-content v-else>
      <template v-slot:content>
        <!-- Page Table -->
        <base-table :columns="columns">
          <template v-slot:table-body v-if="agents.length">
            <tr v-for="agent in agents" :key="'bill_' + agent.id">
              <td>{{ agent.fullName }}</td>
              <td>{{ agent.nmls }}</td>
              <td>{{ agent.lastUpdated ? formatDate(agent.lastUpdated) : 'none' }}</td>
              <td>{{ convertToCurrency(agent.lastCharged) }}</td>
              <td>{{ agent.leads.length }} | {{ convertToCurrency(agent.charges) }}</td>
              <td>
                <div class="d-flex">
                  <button class="btn btn-primary ms-2 me-2" @click="charge(agent)">
                    Charge
                  </button>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:table-body v-else>
            <tr>
              <td colspan="6" class="text-center">
                <div class="m-5">
                  <strong class="no-result">No Records Found...</strong><br>
                </div>
              </td>
            </tr>
          </template>
        </base-table>
      </template>
    </base-page-content>
    <transition name="component-fade" mode="out-in">
      <upload-bank-rate-csv v-if="modals.bankRateUpload" @close="modals.bankRateUpload = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <bankrate-charge-modal-v2 v-if="modals.charge"
                             :agent="selectedAgent"
                             @close="modals.charge = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <SettingModal v-if="modals.setting" @close="closeSettingModal"/>
    </transition>
  </div>
</template>

<script>
import PageSpinner from "../../components/pageSpinner.vue";
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
import BaseTable from "../../components/BaseTable.vue";
import BankrateChargeModalV2 from "@/views/bankrateLead/modals/BankrateChargeModalV2";
import SettingModal from "@/views/bankrateLead/modals/SettingModal";
import UploadBankRateCsv from "@/views/bankrateLead/modals/UploadBankrateCsv";
export default {
  name: "BankrateBillingTool",
  components: {
    BasePageHeader, BasePageContent, BaseTable, PageSpinner, BankrateChargeModalV2, SettingModal, UploadBankRateCsv
  },
  data() {
    return {
      ui: {
        loading: false,
        loadLeads: false,
      },
      columns: ["Agent", "NMLS", "Last updated on", "Last charged", "Total Due", ""],
      agents: [],
      selectedAgent: null,
      modals: {
        bankRateUpload: false,
        charge: false,
        setting: false,
      },
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http.get('/api/v2/bank-rate-tool')
        .then((res) => { this.agents = res.data.agents })
        .catch((err) => { console.log(err) })
        .finally(() => { this.ui.loading = false })
    },
    loadLeads() {
      this.ui.loadLeads = true
      this.$http.get('/api/v2/bank-rate-tool/load-leads')
        .then(() => { this.getData() })
        .catch((err) => { console.log(err) })
        .finally(() => { this.ui.loadLeads = false })
    },
    charge(agent) {
      this.selectedAgent = agent
      this.modals.charge = true
    },
    closeSettingModal(update = false) {
      if (update) {
        this.getData()
      }
      this.modals.setting = false
    }
  },
  beforeMount() {
    this.getData()
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
}

::v-deep {
    .table-block {
        .table {
            .btn {
              &.btn-secondary {
                min-width: 100px;
              }

              &.btn-control {
                width: 36px;
                height: 36px;

                span {
                  font-size: 10px;
                  width: 10px;
                  height: 10px;
                }
              }
            }
        }
    }
}
</style>
